import firebase from "firebase/compat/app";
import React from "react";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
import Footer from "../../components/footer";
import { FullscreenCenter } from "../../components/fullscreen-center";
import { FullscreenLoader } from "../../components/fullscreen-loader";
import { PAGE_LIMIT } from "../../constants";
import { decryptRecordings, isPasswordCorrect } from "../../utils/crypto";
import "./index.css";
import Recording from "./recording";

function RecordingsPage() {
  const { t, i18n } = useTranslation();
  document.description = t("play_store_short_description");
  const [isLoading, setIsLoading] = React.useState(true);
  const [recordings, setRecordings] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [visibleRecordings, setVisibleRecordings] = React.useState([]);
  const [startedAt, setStartedAt] = React.useState(null);
  const [stoppedAt, setStoppedAt] = React.useState(null);
  const [settings, setSettings] = React.useState(null);
  const sessionId = window.location.pathname.split("/")[1];
  let password = "";
  let passwordAttempts = 0;

  const processData = (data) => {
    let recordings = data.recordings;
    if (recordings) {
      recordings = Object.entries(data.recordings)
        .map((v) => v[1])
        .sort((a, b) => b.id - a.id);
      setRecordings(recordings);
    }
    setStartedAt(data.started_at);
    setStoppedAt(data.stopped_at);
    const userId = data.user_id;

    const settings = data.settings;
    setSettings(settings);
    if (settings && settings.lang) {
      i18n.changeLanguage(settings.lang).catch((reason) => {
        console.error(JSON.stringify(reason));
      });
    }
    if (settings && settings.hash) {
      while (!isPasswordCorrect(password, userId, settings.hash)) {
        password = prompt(t("popup_password_enter_password") + ":");
        if (++passwordAttempts >= 10) {
          return;
        }
      }

      if (recordings) {
        setRecordings(decryptRecordings(password, recordings));
      }
    }
    setIsLoading(false);
  };

  React.useEffect(() => {
    if (!sessionId) {
      if (isLoading) {
        setIsLoading(false);
      }
    }
    // link to test http://localhost:3000/-N0fbVrCvICNlKZ__1Ci
    firebase
      .database()
      .ref(`/sessions/${sessionId}`)
      .on("value", function (snapshot) {
        const data = snapshot.val();
        if (data) {
          processData(data);
        } else {
          setIsLoading(false);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setVisibleRecordings(
      recordings.slice(currentPage * PAGE_LIMIT, (currentPage + 1) * PAGE_LIMIT)
    );
  }, [currentPage, recordings]);

  return (
    <>
      <div className="recordings-content">
        {isLoading && <FullscreenLoader />}
        {sessionId && !isLoading && recordings.length === 0 && (
          <FullscreenCenter>
            <h4>{t("screen_tracking_not_started_yet")}</h4>
          </FullscreenCenter>
        )}
        {sessionId && !isLoading && recordings.length > 0 && (
          <div>
            <div>
              {startedAt && (
                <h5 className="recordings-header-text">
                  {t("web_started_time") +
                    " " +
                    new Date(startedAt).toLocaleString()}
                </h5>
              )}
              {stoppedAt && (
                <h5 className="recordings-header-text">
                  {t("web_stopped_time") +
                    " " +
                    new Date(stoppedAt).toLocaleString()}
                </h5>
              )}
              {startedAt && !stoppedAt && (
                <h6 className="recordings-header-text">
                  {t("screen_tracking_tracking_in_progress")}
                </h6>
              )}
            </div>
            {visibleRecordings.map((recording) => (
              <div key={recording.id} className="recordings">
                <Recording recording={recording} settings={settings} />
              </div>
            ))}
            <nav style={{ marginTop: 50 }}>
              <ReactPaginate
                pageCount={Math.ceil(recordings.length / PAGE_LIMIT)}
                pageRangeDisplayed={5}
                marginPagesDisplayed={2}
                onPageChange={({ selected }) => {
                  setCurrentPage(selected);
                  window.scrollTo(0, 0);
                }}
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                containerClassName={"pagination justify-content-center"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                activeClassName={"active"}
                activeLinkClassName={"active"}
              />
            </nav>
          </div>
        )}

        {!isLoading && <Footer />}
      </div>
    </>
  );
}

export default RecordingsPage;
