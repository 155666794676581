import React from "react";

export const PlayStoreBanner = () => {
  return (
    <div>
      <a href="https://play.google.com/store/apps/details?id=app.nedze&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
        <img
          alt="Get it on Google Play"
          src={window.location.origin + "/play_badge.png"}
          width="200px"
        />
      </a>
    </div>
  );
};
