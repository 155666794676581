import React from "react";
import { FullscreenCenter } from "../../components/fullscreen-center";
import { PlayStoreBanner } from "../../components/play_store_banner";

function HomePage() {
  return (
    <>
      <FullscreenCenter>
        <PlayStoreBanner />
      </FullscreenCenter>
    </>
  );
}

export default HomePage;
