import {
  faBatteryEmpty,
  faBatteryFull,
  faBatteryHalf,
  faBatteryQuarter,
  faBatteryThreeQuarters,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { memo } from "react";

const BatteryIcon = ({ battery }) => {
  return (
    <FontAwesomeIcon
      icon={getBatteryIcon(battery)}
      style={{ marginLeft: "2rem" }}
    />
  );
};

function getBatteryIcon(battery) {
  if (battery > 87.5) {
    return faBatteryFull;
  } else if (battery > 62.5) {
    return faBatteryThreeQuarters;
  } else if (battery > 37.5) {
    return faBatteryHalf;
  } else if (battery > 12.5) {
    return faBatteryQuarter;
  } else {
    return faBatteryEmpty;
  }
}

export default memo(BatteryIcon);
