import React, { memo } from "react";
import { PlayStoreBanner } from "../play_store_banner";
import "./index.css";

const Footer = () => {
  return (
    <footer className="page-footer">
      <PlayStoreBanner />
    </footer>
  );
};

export default memo(Footer);
