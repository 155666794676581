import CryptoJS from "crypto-js";

// val encrypted = settings.hash is not empty.
// Show enter password popup. Block input until password is successful. sha256(user.id + password) = settings.hash
// Decrypt data. Each recording contains encrypted field.
export function isPasswordCorrect(password, userId, hash) {
  return (
    CryptoJS.enc.Base64.stringify(CryptoJS.SHA256(userId + password)) === hash
  );
}

global.decryptionCache = new Map();

// Decryption flow:
// Split string to array of 3 strings via ".". Result will be salt(used to mix with password), iv(algorithm parameters), encryptedData
// Decode them as Base64-encoded String data in byte arrays (or no need, depends on library input)
// Generate key = PBKDF2 hash for the configured password using the provided salt. val key = pbkdf2(password, salt, iterations = 1000, keylen = 128, digest = sha256).
// Init Cipher in decrypt mode with AES/CBCPKCS5Padding transformation
// Decrypt data using using Cipher, key and iv
// Convert byte array to UTF_8 String
function decryptRecording(password, recording) {
  if (global.decryptionCache.has(recording.id)) {
    return global.decryptionCache.get(recording.id);
  }
  const digest = recording.encrypted.split(".");
  const salt = CryptoJS.enc.Base64.parse(digest[0]);
  const iv = CryptoJS.enc.Base64.parse(digest[1]);
  const encryptedData = CryptoJS.enc.Base64.parse(digest[2]);
  const key = CryptoJS.PBKDF2(password, salt, {
    iterations: 128,
    keySize: 128 / 32,
  });
  const decrypted = CryptoJS.AES.decrypt(
    {
      ciphertext: encryptedData,
    },
    key,
    {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }
  ).toString(CryptoJS.enc.Utf8);
  const result = JSON.parse(decrypted);
  global.decryptionCache.set(recording.id, result);
  return result;
}

// Encrypted data is same as existing data but need to be attached in memory after parsing. Possible results of encrypted operation:
// type == location: {latitude, longitude, accuracy}
// type == audio/image/video: {file, url}. We actually need only url here.
// type == wifi: [{ssid, address, level, time},...]
// type == bluetooth [{name, address, level, time},...]
export function decryptRecordings(password, recordings) {
  return recordings.map((recording) => {
    if (recording.encrypted) {
      if (recording.type === "location") {
        return {
          ...recording,
          ...decryptRecording(password, recording),
        };
      } else if (
        recording.type === "image" ||
        recording.type === "video" ||
        recording.type === "audio"
      ) {
        return {
          ...recording,
          ...decryptRecording(password, recording),
        };
      } else if (recording.type === "wifi") {
        return {
          ...recording,
          wifi: decryptRecording(password, recording),
        };
      } else if (recording.type === "bluetooth") {
        return {
          ...recording,
          bluetooth: decryptRecording(password, recording),
        };
      }
    }

    return recording;
  });
}
