import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

async function loadTranslations() {
  const context = require.context("./locales", false, /\.json$/);
  const translations = {};

  for (const key of context.keys()) {
    const lang = key.replace("./", "").replace(".json", "");
    translations[lang] = {
      translation: context(key),
    };
  }

  return translations;
}

async function initializeI18n() {
  const resources = await loadTranslations();

  await new Promise((resolve, reject) => {
    i18n
      .use(LanguageDetector)
      .use(initReactI18next)
      .init({
        resources,
        fallbackLng: {
          pt: ["pt-PT", "en"],
          zh: ["zh-CN", "en"],
          fa: ["fa-IR", "en"],
          default: ["en"],
        },
        interpolation: {
          escapeValue: false, // react already safes from xss
        },
        initImmediate: false, // this will make it sync mode
        callback: (err) => {
          if (err) reject(err);
          resolve();
        },
      });
  });
}

initializeI18n().catch((error) => {
  console.error("Failed to initialize i18n:", error);
});

export default i18n;
