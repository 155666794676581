import React from "react";
import { Spinner } from "react-bootstrap";
import { FullscreenCenter } from "../fullscreen-center";

export const FullscreenLoader = () => {
  return (
    <FullscreenCenter>
      <Spinner
        animation="border"
        style={{ width: 50, height: 50 }}
        role="status"
        variant="danger"
      >
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </FullscreenCenter>
  );
};
