import React, { Fragment, memo } from "react";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { IS_PROD } from "../../constants";
import BatteryIcon from "./battery";

const Recording = ({ recording, settings }) => {
  const { t } = useTranslation();
  return (
    <div>
      {!recording.battery && <h6>{new Date(recording.id).toLocaleString()}</h6>}
      {recording.battery && (
        <h6>
          {
            <Fragment>
              {new Date(recording.id).toLocaleString()}
              <BatteryIcon battery={recording.battery} />
              {" " + recording.battery + "% "}
            </Fragment>
          }
        </h6>
      )}
      {recording.type === "location" && (
        <iframe
          title={`${recording.latitude},${recording.longitude}`}
          width="95%"
          style={{ maxWidth: 700 }}
          height="500"
          src={
            "https://www.google.com/maps/embed/v1/place?maptype=satellite" +
            `&q=${recording.latitude},${recording.longitude}` +
            "&zoom=" +
            (recording.accuracy > 250
              ? 16
              : recording.accuracy > 100
              ? 17
              : recording.accuracy > 50
              ? 18
              : 19) +
            "&key=" +
            (IS_PROD
              ? process.env.REACT_APP_MAPS_KEY_PROD
              : process.env.REACT_APP_MAPS_KEY_STAGING) +
            "&language=" +
            (settings && settings.lang ? settings.lang : "en")
          }
        />
      )}
      {recording.type === "image" && (
        <img src={recording.url} alt="" className="recordings-media" />
      )}
      {recording.type === "audio" && (
        <audio controls preload="none">
          <source src={recording.url} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
      )}
      {recording.type === "video" && (
        <video controls preload="metadata" className="recordings-media">
          <source src={recording.url + "#t=0.1"} type="video/mp4" />
          Your browser does not support the video element.
        </video>
      )}
      {recording.type === "wifi" && recording.wifi && (
        <Table striped bordered hover responsive size="sm">
          <thead>
            <tr>
              <th className="text-center">Wi-Fi</th>
              <th className="text-center">
                {t("screen_manage_data_wifi_address")}
              </th>
              <th className="text-center">
                {t("screen_manage_data_wifi_signal")}
              </th>
            </tr>
          </thead>
          <tbody>
            {recording.wifi.map((row, i) => (
              <tr key={i}>
                <td>{row.ssid}</td>
                <td>{row.address}</td>
                <td>{row.level + " dbM"}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      {recording.type === "bluetooth" && recording.bluetooth && (
        <Table striped bordered hover responsive size="sm">
          <thead>
            <tr>
              <th className="text-center">Bluetooth</th>
              <th className="text-center">
                {t("screen_manage_data_wifi_address")}
              </th>
              <th className="text-center">
                {t("screen_manage_data_wifi_signal")}
              </th>
            </tr>
          </thead>
          <tbody>
            {recording.bluetooth.map((row, i) => (
              <tr key={i}>
                <td>{row.name}</td>
                <td>{row.address}</td>
                <td>{row.level + " dbM"}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default memo(Recording);
