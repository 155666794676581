import firebase from "firebase/compat/app";
import "firebase/compat/app-check";
import "firebase/compat/database";
import React from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import { FullscreenCenter } from "./components/fullscreen-center";
import { IS_PROD } from "./constants";
import "./i18n";
import HomePage from "./pages/home/home";
import RecordingsPage from "./pages/recordings";
import RemotePage from "./pages/remote";

if (IS_PROD) {
  firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_KEY_PROD,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN_PROD,
    databaseURL: process.env.REACT_APP_DB_URL_PROD,
    projectId: process.env.REACT_APP_PROJECT_ID_PROD,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET_PROD,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID_PROD,
    appId: process.env.REACT_APP_APP_ID_PROD,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID_PROD,
  });
  firebase
    .appCheck()
    .activate(process.env.REACT_APP_APP_CHECK_TOKEN_PROD, true);
} else {
  firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_KEY_STAGING,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN_STAGING,
    databaseURL: process.env.REACT_APP_DB_URL_STAGING,
    projectId: process.env.REACT_APP_PROJECT_ID_STAGING,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET_STAGING,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID_STAGING,
    appId: process.env.REACT_APP_APP_ID_STAGING,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID_STAGING,
  });
  firebase
    .appCheck()
    .activate(process.env.REACT_APP_APP_CHECK_TOKEN_STAGING, true);
}

function App() {
  return (
    <main className="App">
      <Routes>
        <Route path="/:sessionId" element={<RecordingsPage />} />
        <Route path="/r/:linkId" element={<RemotePage />} />
        <Route path="/" exact element={<HomePage />} />
        <Route
          path="*"
          element={<FullscreenCenter>404 Page not found.</FullscreenCenter>}
        />
      </Routes>
    </main>
  );
}

export default App;
