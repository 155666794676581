import "@material/mwc-button";
import "@material/mwc-icon";
import firebase from "firebase/compat/app";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { FullscreenCenter } from "../../components/fullscreen-center";
import { FullscreenLoader } from "../../components/fullscreen-loader";
import "./index.css";

function RemotePage() {
  const { linkId } = useParams();
  const { t } = useTranslation();
  const [link, setLink] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [currentSession, setCurrentSession] = React.useState(null);
  const [sessionsHistory, setSessionsHistory] = React.useState([]);
  const [showHistory, setShowHistory] = React.useState(false);
  document.description = t("play_store_short_description");

  const processData = (data) => {
    setLink(data);
    let sessions = data.sessions;
    if (sessions) {
      sessions = Object.entries(data.sessions)
        .map((v) => {
          let session = v[1];
          let map = new Map();

          const startedAt = session.started_at;
          if (startedAt) {
            map.set(
              startedAt,
              t("remote_start_started").replace(
                "%s",
                new Date(startedAt).toLocaleTimeString()
              )
            );
          }

          const connectedAt = session.connected_at;
          if (connectedAt) {
            map.set(
              connectedAt,
              t("remote_start_connected_to_the_server").replace(
                "%s",
                new Date(connectedAt).toLocaleTimeString()
              )
            );
          } else {
            map.set(startedAt + 1, t("remote_start_connecting_to_the_server"));
          }

          const pushReceivedAt = session.push_received_at;
          if (connectedAt && pushReceivedAt == null) {
            map.set(
              connectedAt + 1,
              t("remote_start_connecting_to_the_device")
            );
          }

          const safetyCheckImOkAt = session.safety_check_im_ok_at;
          if (safetyCheckImOkAt) {
            map.set(
              safetyCheckImOkAt,
              t("remote_start_safety_check_im_ok").replace(
                "%s",
                new Date(safetyCheckImOkAt).toLocaleTimeString()
              )
            );
          }

          const safetyCheckPostponedAt = session.safety_check_postponed_at;
          if (safetyCheckPostponedAt) {
            map.set(
              safetyCheckPostponedAt,
              t("remote_start_safety_check_postponed").replace(
                "%s",
                new Date(safetyCheckPostponedAt).toLocaleTimeString()
              )
            );
          }

          const safetyCheckStoppedAt = session.safety_check_stopped_at;
          if (safetyCheckStoppedAt) {
            map.set(
              safetyCheckStoppedAt,
              t("remote_start_safety_check_stopped").replace(
                "%s",
                new Date(safetyCheckStoppedAt).toLocaleTimeString()
              )
            );
          }

          const safetyCheckStartedAt = session.safety_check_started_at;
          if (safetyCheckStartedAt) {
            map.set(
              safetyCheckStartedAt,
              t("remote_start_safety_check_started").replace(
                "%s",
                new Date(safetyCheckStartedAt).toLocaleTimeString()
              )
            );
          }

          const trackingStartedAt = session.tracking_started_at;
          if (trackingStartedAt) {
            map.set(
              trackingStartedAt,
              t("remote_start_tracking_started").replace(
                "%s",
                new Date(trackingStartedAt).toLocaleTimeString()
              )
            );
          }

          const cancelledAt = session.cancelled_at;
          if (cancelledAt) {
            map.set(
              cancelledAt,
              t("remote_start_cancelled").replace(
                "%s",
                new Date(cancelledAt).toLocaleTimeString()
              )
            );
          }

          const stoppedAt = session.stopped_at;
          if (stoppedAt) {
            map.set(
              stoppedAt,
              t("remote_start_stopped").replace(
                "%s",
                new Date(stoppedAt).toLocaleTimeString()
              )
            );
          }

          session.log = Array.from(
            new Map([...map].sort((a, b) => a[0] - b[0]))
          );
          return session;
        })
        .sort((a, b) => b.started_at - a.started_at);
      setCurrentSession(sessions.shift());
      setSessionsHistory(sessions);
    }
    setIsLoading(false);
  };

  React.useEffect(() => {
    // link to test http://localhost:3000/r/-N0JkTughFjDs_hGmMhx
    if (!linkId) {
      if (isLoading) {
        setIsLoading(false);
      }
    }
    firebase
      .database()
      .ref(`/links/${linkId}`)
      .on("value", function (snapshot) {
        const data = snapshot.val();
        if (data) {
          processData(data);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function start(linkType) {
    let linkRef = firebase.database().ref(`/links/${linkId}`);
    let sessionRef = linkRef.child("sessions").push();
    return Promise.all([
      sessionRef.set({
        id: sessionRef.ref.key,
        type: linkType,
        created_by: "web",
        started_at: Date.now(),
      }),
      linkRef.child("session").set(sessionRef.ref.key),
    ]);
  }

  function stop() {
    let linkRef = firebase.database().ref(`/links/${linkId}`);
    linkRef.child("session_stop_at").set(Date.now());
  }

  function displaySession(session) {
    return (
      <div key={session.id} className="remote-card">
        {session.type === "emergency_tracking" && (
          <h5 className="color-primary">
            {t("remote_start_emergency_tracking")}
          </h5>
        )}
        {session.type === "safety_check" && (
          <h5 className="color-primary">{t("remote_start_safety_check")}</h5>
        )}
        {session.log.map(([ts, logText]) => (
          <p className="remote-log" key={ts}>
            {logText}
          </p>
        ))}

        {session.tracking_id && (
          <div className="remote-card-button-wrapper">
            <mwc-button
              class="remote-button-primary"
              raised
              onClick={() => {
                return window.open(
                  `${window.location.origin}/${session.tracking_id}`,
                  "_self"
                );
              }}
            >
              {t("remote_start_connected_open_tracking")}
            </mwc-button>
          </div>
        )}
      </div>
    );
  }

  return (
    <>
      <div className="remote-content">
        {isLoading && <FullscreenLoader />}
        {!isLoading && !currentSession && (
          <FullscreenCenter>
            <h4>{t("remote_start_no_data")}</h4>
          </FullscreenCenter>
        )}
        {!isLoading && currentSession && (
          <div>{displaySession(currentSession)}</div>
        )}
        {!isLoading && sessionsHistory.length > 0 && (
          <div>
            <mwc-button
              style={{ marginTop: 12, marginBottom: 24 }}
              class="remote-button-primary"
              outlined
              onClick={() => {
                return setShowHistory(!showHistory);
              }}
            >
              {t("remote_start_history")}
            </mwc-button>
          </div>
        )}
        {!isLoading && showHistory && (
          <div>{sessionsHistory.map((session) => displaySession(session))}</div>
        )}

        <div className="remote-fixed-buttons-wrapper">
          {!isLoading && link.is_emergency_tracking && link.session == null && (
            <mwc-button
              class="remote-button-fab"
              raised
              icon="emergency_share"
              onClick={() => {
                return start("emergency_tracking");
              }}
            >
              {t("remote_start_emergency_tracking")}
            </mwc-button>
          )}
          {!isLoading && link.session == null && (
            <mwc-button
              style={{ marginTop: 24 }}
              class="remote-button-fab"
              raised
              icon="safety_check"
              onClick={() => {
                return start("safety_check");
              }}
            >
              {t("remote_start_safety_check")}
            </mwc-button>
          )}
          {!isLoading && link.session != null && link.session_stop_at == null && (
            <mwc-button
              class="remote-button-fab"
              raised
              icon="cancel"
              onClick={() => {
                return stop();
              }}
            >
              {link.sessions[link.session].push_received_at != null
                ? t("remote_start_stop_tracking")
                : t("remote_start_cancel_remote_start")}
            </mwc-button>
          )}
        </div>
      </div>
    </>
  );
}

export default RemotePage;
